<script setup lang="ts">
const props = defineProps({
    type: { type: String as PropType<"error" | "success" | "warning" | "info">, required: true, default: 'info'},
    closable: { type: Boolean, default: false }
});

const emit = defineEmits(['close']);
</script>

<template>
    <v-alert
        :closable="props.closable"
        :prominent="true"
        class="alert"
        :class="props.type" 
        :type="props.type"
        closeIcon="ri-close-line icon"
        @click:close="emit('close')">
        <template #prepend>
            <div class="prepend-line"/>
        </template>
        <template #title>
            <div class="title">
                <slot name="title"/>
            </div>
        </template>
        <template #text>
            <div class="text">
                <slot name="text"/>
            </div>
        </template>
    </v-alert>
</template>

<style scoped lang="scss">
    .alert {
        padding: var(--Padding-pa-3, 12px);
        gap: var(--Padding-pa-2, 8px);
        border-radius: var(--Border-radius-rounded-lg, 12px);
        
        &.error {
            background-color: $alert-error-bg-color !important;
            color: $alert-error-text-color !important;
        }

        &.success {
            background-color: $alert-success-bg-color !important;
            color: $alert-success-text-color !important;
        }
        
        &.warning {
            background-color: $alert-warning-bg-color !important;
            color: $alert-warning-text-color !important;
        }
        
        &.info {
            background-color: $alert-info-bg-color !important;
            color: $alert-info-text-color !important;
        }

        .title {
            font-size: 16px;
            font-weight: 700;
            line-height: 125%;
            letter-spacing: -0.16px;
        }

        .text {
            font-size: 14px;
            font-weight: 400;
            line-height: 125%;
            letter-spacing: -0.14px;
        }

        .close {
            display: flex;
            align-items: flex-start;
            justify-self: flex-start;
            cursor: pointer;

            .icon {
                font-size: 20px;
            }
        }

        :deep(.v-alert__prepend) {
            margin: 0;
        }

        :deep(.v-alert__content) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: var(--padding-pa-05, 2px);

            .v-alert-title {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                align-self: flex-start;
            }
        }
        
        /** Чтобы работала custom иконка */
        .mdi:before,
        .mdi:after {
            font: inherit;
            font-size: 20px;
        }
    } 
</style>